import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./NFT.css";
import { useParams } from "react-router";
import {
  fetchSingleNFTStart,
  deleteSingleNFTStart,
} from "../store/actions/NFTActions";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Web3 from "web3";
import NftLoot from "../../abis/RichApeCarClub.json";
import MintNowModal from "./MintNowModal";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { apiConstants } from "../Constant/constants";
import configuration from "react-global-configuration";

//Declare IPFS
const auth =
  "Basic" +
  Buffer.from(
    apiConstants.ipfs_project_id + ":" + apiConstants.ipfs_project_secret
  ).toString("base64");
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
}); // leaving out the arguments will default to these values

// Declare IPFS json
const ipfsJson = require("nano-ipfs-store").at("https://ipfs.infura.io:5001");

const SingleNFT = (props) => {
  const { id } = useParams();

  const { u_id } = useParams();

  useEffect(() => {
    props.dispatch(fetchSingleNFTStart({ nft_image_id: id }));
  }, []);

  const handleDeleteNFT = () => {
    props.dispatch(deleteSingleNFTStart({ nft_image_id: id }));
  };

  const [currentPage, setCurrentPage] = useState(0);

  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const [pageCount, setPageCount] = useState(0);

  const [deleteNFT, setDeleteNFT] = useState(null);

  const [skipInitialRender, setSkipInitialRender] = useState(false);

  const history = useHistory();

  const [nftLoot, setNftLoot] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tokenURIs, setTokenURLs] = useState([]);
  const [connectMetaMask, setConnectMetaMask] = useState(false);
  const [account, setAccount] = useState("");
  const [ethBalance, setEthBalance] = useState("");
  const [minterWalletAddress, setMinterWalletAdddress] = useState("");
  const [mintButtonContent, setMintButtonContent] = useState("");
  const [inputData, setInputData] = useState({
    title: "",
    description: "",
  });
  const [images, setImages] = useState([]);
  const [img, setImg] = useState("");
  const [nftWords, setNftWords] = useState([]);
  const [mintNowModal, setMintNowModal] = useState(false);

  const [mintNowinputData, setMintNowInputData] = useState({
    wallet_address: "",
  });

  const [searchInputData, setSearchInputData] = useState({
    searchKey: "",
    searchStatus: false,
  });

  const connectingMetaMask = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      console.log("Etherum enabled");
      setConnectMetaMask(true);
      getWalletAddress();
      return true;
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      setConnectMetaMask(true);
      getWalletAddress();
      return true;
    } else {
      setConnectMetaMask(false);
      const notificationMessage = getErrorNotificationMessage(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }
  };

  const getWalletAddress = async () => {
    const web3 = window.web3;
    try {
      // Network ID
      const networkId = await web3.eth.net.getId();
      const networkData = NftLoot.networks[networkId];
      if (networkData) {
        const nftLoot = new web3.eth.Contract(
          NftLoot.abi,
          networkData.address
        );
        setNftLoot(nftLoot);
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);

        const ethBalance = await web3.eth.getBalance(accounts[0]);
        setEthBalance(ethBalance);
        setConnectMetaMask(true);
      } else {
        setConnectMetaMask(false);
        const notificationMessage = getErrorNotificationMessage(
          "Contract not deployed to detected network."
        );
        props.dispatch(createNotification(notificationMessage));
      }
    } catch (error) {
      setConnectMetaMask(false);
      const notificationMessage = getErrorNotificationMessage(
        "Something went wrong. Please refresh the page and try again."
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const convertDataURIToBinaryFF = (dataURI) => {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var raw = window.atob(dataURI.substring(base64Index));
    return Uint8Array.from(
      Array.prototype.map.call(raw, function (x) {
        return x.charCodeAt(0);
      })
    );
  };

  // Generate metadata json file.
  const generateJson = async (data) => {
    const metadata = JSON.stringify({
      description: data.description,
      external_url: "https://richapecarclub.io/",
      image: "https://ipfs.infura.io/ipfs/" + data.imageHash,
      name: data.name,
      text: data.allWords,
      attributes: [
        {
          "trait_type": "Background",
          "value": data.background
        }, {
          "trait_type": "Car",
          "value": data.car
        }, {
          "trait_type": "Fur",
          "value": data.fur
        }, {
          "trait_type": "Eye",
          "value": data.eye
        }, {
          "trait_type": "Hat",
          "value": data.hat
        }, {
          "trait_type": "Mouth",
          "value": data.mouth
        },
      ],
    });
    console.log("Json", metadata);
    return metadata;
  };

  const mintYourNFT = async (event) => {
    event.preventDefault();
    setMintButtonContent("Initiated...");
    if (nftLoot != null) {
      // fetch data from api.
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({ color: colorName, mouth: mouthName, eye: eyeName, picture: image })
      };
      try {
        let currentData = props.singleNFT.data.nft_image;
        setMintButtonContent((prevState) => "Connecting to Blockchain");

        let imageData = await convertDataURIToBinaryFF(
          "data:image/png;base64," + currentData.picture_base
        );

        imageData = Buffer(imageData);

        let allWords = "";
        allWords = currentData.nft_properties.background + " " + currentData.nft_properties.car + " " + currentData.nft_properties.eye + " "
          + currentData.nft_properties.fur + " " + currentData.nft_properties.hat + " " + currentData.nft_properties.mouth;

        //adding file to the IPFS

        ipfs.add(imageData, async (error, result) => {
          console.log("Ipfs result", result);
          if (error) {
            console.error(error);
            return;
          }
          setImg(result[0].hash);

          const json = generateJson({
            name: currentData.name,
            description: currentData.description,
            imageHash: result[0].hash,
            allWords: allWords,
            background: currentData.nft_properties.background, car: currentData.nft_properties.car, eye: currentData.nft_properties.eye,
            fur: currentData.nft_properties.fur, hat: currentData.nft_properties.hat, mouth: currentData.nft_properties.mouth,
          }).then(async (val) => {
            try {
              const cid = await ipfsJson.add(val);
              const tokenURIHash = await ipfsJson.cat(cid);
              console.log("cid", cid);
              console.log("minter address", minterWalletAddress);
              console.log("all words", allWords);
              nftLoot.methods
                .safeMint(
                  minterWalletAddress,
                  "https://ipfs.infura.io/ipfs/" + cid,
                  allWords
                )
                .send({ from: account })
                .on("error", (error) => {
                  let notificationMessage;
                  if (error.message == undefined) {
                    notificationMessage = getErrorNotificationMessage(
                      "Same Wallet can't have more than 2 NFT! Use different wallet address"
                    );
                  } else {
                    notificationMessage = getErrorNotificationMessage(
                      error.message
                    );
                  }
                  props.dispatch(createNotification(notificationMessage));
                  setMintButtonContent("");
                  handleMintNowModalClose();
                })
                .once("receipt", (receipt) => {
                  setLoading(false);
                  setMintButtonContent("");
                  setImages((prevState) => [...prevState, result[0].hash]);
                  setNftWords((prevState) => [...prevState, allWords]);
                  sendTransactionApi(
                    receipt.transactionHash,
                    currentData.nft_image_id
                  );
                });
            } catch (error) {
              const notificationMessage = getErrorNotificationMessage(
                "Invalid wallet address"
              );
              props.dispatch(createNotification(notificationMessage));
              setMintButtonContent("");
              handleMintNowModalClose();
            }
          });
        });
      } catch (error) {
        setMintButtonContent("");
        const notificationMessage = getErrorNotificationMessage(
          "Something went wrong. Please refresh the page and try again."
        );
        props.dispatch(createNotification(notificationMessage));
      }
    } else {
      setMintButtonContent("");
      const notificationMessage = getErrorNotificationMessage(
        "Something went wrong. Please refresh the page and try again."
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const sendTransactionApi = (transactionHash, nft_image_id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        transaction_id: transactionHash,
        wallet_address: minterWalletAddress,
        nft_image_id: nft_image_id,
      }),
    };
    try {
      fetch(
        "https://api.nft-generator.online/api/user/nft_images_complete",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            const notificationMessage = getSuccessNotificationMessage(
              "Minted Successfully"
            );
            props.dispatch(createNotification(notificationMessage));
            window.location.reload();
          } else {
            const notificationMessage = getErrorNotificationMessage(
              "Saving data error.."
            );
            props.dispatch(createNotification(notificationMessage));
          }
        });
    } catch (error) {
      const notificationMessage = getErrorNotificationMessage(
        "Something went wrong. Please refresh the page and try again."
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const handleMintNowModalClose = () => {
    setMintNowModal(false);

    setMinterWalletAdddress("");
  };

  const handleMintModalShow = () => {
    if (connectMetaMask) {
      setMintNowModal(true);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        "Connect your Wallet, Before mint"
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  return (
    <>
      <main className="h-full overflow-y-auto content-wrapper">
        <div className="container px-6 mx-auto grid content-section">
          <div class="apps-card">
            <div className="text-right p-3 flex-1 flex-grow">
              <button
                onClick={connectingMetaMask}
                class=" px-4 py-2 text-sm font-medium leading-5 text-white content-button status-button"
              >
                {connectMetaMask ? account : "Connect Wallet"}
              </button>
            </div>
            <div className="w-full overflow-hidden app-card my-4 w-100">
              <div className="w-full">
                <div className="single-nft-box">
                  {props.singleNFT.loading
                    ? "Loading"
                    : props.singleNFT.data.nft_image && (
                      <>
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="single-nft-card">
                              <h4>Name</h4>
                              <p>{props.singleNFT.data.nft_image.name}</p>
                            </div>
                            <div className="single-nft-card">
                              <h4>NFT ID:</h4>
                              <p>
                                {
                                  props.singleNFT.data.nft_image
                                    .nft_image_unique_id
                                }
                              </p>
                            </div>
                            <div className="single-nft-card">
                              <h4>Status:</h4>
                              <div>
                                {props.singleNFT.data.nft_image.status < 3 ? (
                                  <>
                                    <p className="open-status">
                                      {
                                        props.singleNFT.data.nft_image
                                          .status_formatted
                                      }
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p className="closed-status">
                                      {
                                        props.singleNFT.data.nft_image
                                          .status_formatted
                                      }
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="single-nft-card">
                              <h4>Active Status:</h4>
                              <div>
                                {props.singleNFT.data.nft_image
                                  .active_status == 1 ? (
                                  <>
                                    <p className="active-status">
                                      {
                                        props.singleNFT.data.nft_image
                                          .active_status_formatted
                                      }
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p className="inactive-status">
                                      {
                                        props.singleNFT.data.nft_image
                                          .active_status_formatted
                                      }
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="single-nft-card">
                              <h4>Properties</h4>
                            </div>
                            {Object.entries(
                              props.singleNFT.data.nft_image.nft_properties
                            ).map((data, index) => (
                              <div className="single-nft-card">
                                <h4>{data[0]}</h4>
                                <p>{data[1]}</p>
                              </div>
                            ))}

                            <div className="single-nft-card">
                              <h4>Properties:</h4>
                              <div className="single-nft-properties">
                                {Object.entries(
                                  props.singleNFT.data.nft_image
                                    .nft_properties
                                )
                                  .filter(
                                    (data) =>
                                      data[0] != "nft_image_id" &&
                                      data[0] != "created_at" &&
                                      data[0] != "updated_at" &&
                                      data[0] != "nft_property_id" &&
                                      data[0] != "nft_property_unique_id" &&
                                      data[0] != "status"
                                  )
                                  .map((data, index) => (
                                    <>
                                      <div className="color-pro" key={index}>
                                        {data[1]}
                                      </div>
                                    </>
                                  ))}
                              </div>
                            </div>
                            <div className="single-nft-card">
                              <h4>Action:</h4>
                              <div className="flex item-center">
                                <button
                                  onClick={(event) => {
                                    handleMintModalShow();
                                  }}
                                  className="mr-2 flex items-center justify-between px-4 py-2 text-sm font-medium content-button status-button"
                                >
                                  <span>
                                    {mintButtonContent != ""
                                      ? mintButtonContent
                                      : "Mint Now"}
                                  </span>
                                </button>
                                <Link
                                  to={`/edit-nft/${u_id}/${id}`}
                                  className="mr-2 px-4 py-2 text-sm font-medium leading-5 text-white content-button status-button"
                                >
                                  Edit
                                </Link>
                                <button
                                  onClick={() => handleDeleteNFT()}
                                  disabled={
                                    props.deleteSingleNFT.buttonDisabled
                                  }
                                  className="flex items-center justify-between px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 "
                                >
                                  <span>
                                    {props.deleteSingleNFT
                                      .loadingButttonContent != null
                                      ? props.deleteSingleNFT
                                        .loadingButttonContent
                                      : "Delete"}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="single-nft-img-sec ">
                              <img
                                className="single-nft-img"
                                src={props.singleNFT.data.nft_image.picture}
                                alt="Office"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <MintNowModal
        show={mintNowModal}
        handleClose={handleMintNowModalClose}
        minterWalletAddress={minterWalletAddress}
        setMinterWalletAdddress={setMinterWalletAdddress}
        mintYourNFT={mintYourNFT}
        mintButtonContent={mintButtonContent}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  singleNFT: state.nft.singleNFT,
  deleteSingleNFT: state.nft.deleteSingleNFT,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleNFT);
